import React from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import SEO from "../../components/archive/seo"

const Iframe = styled.iframe`
  width: 100%;
  height: 100vh;
  display: block;
  border: none;
`

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0
  }
`

const Team = () => (
  <>
    <SEO title="CIS Team Survey" />
    <GlobalStyle />
    <Iframe
      src="https://pumpjackdataworks.logictry.com/apps/609c2d505d8c1145de54ec46?apiKey=609c30d23bf34786d06192e3"
      frameborder="0"
      scrolling="no"
    />
  </>
)

export default Team
